import styled from "@emotion/styled";

const Anchor = styled.a`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: background-image 0.25s var(--ease-in-out-quad);
  color: ${p => p.theme.colors.secondary};
  border-bottom: 2px solid ${p => p.theme.colors.card};
  position: relative;

  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: -2px;
    content: "";
    display: block;
    height: 2px;
    left: 0%;
    position: absolute;
    background: ${p => p.theme.colors.accentGradient};
    transition: width 0.25s ease 0s, left 0.25s ease 0s;
    width: 0;
  }

  &:hover,
  &:focus {
    color: transparent;
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text;
    background-size: 100%;
    background-image: ${p => p.theme.colors.accentGradient};
    background-color: ${p => p.theme.colors.accent};

    &::after {
      width: 100%; 
      left: 0; 
    }
  }

`;

export default Anchor;
